import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import {
  FLayout,
  FHeader,
  FSidebar,
  FGreatings,
  FButtonBox,
  FLogo,
  MI,
} from "../components";
import { useAppStore } from "../App.store";
import { useAuth } from "../context/Auth";

export function PStart() {
  let { user } = useAuth();
  let { currentOrg, mode, toggleDarkTheme } = useAppStore();

  let [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  let dataContent: Array<{
    title: string;
    cards: Array<{
      to: string;
      label: string;
      description?: string;
      Icon: any;
      isHidden?: boolean;
    }>;
  }> = React.useMemo(
    () => [
      {
        title: "Vendas",
        cards: [
          {
            to: "/app/sales/gas",
            label: "Combustíveis",
            description: "Por período",
            Icon: MI.LocalGasStation,
          },
          {
            to: "/app/sales/products",
            label: "Produtos",
            description: "Por período",
            Icon: MI.Store,
          },
        ],
      },
      {
        title: "Estoque",
        cards: [
          {
            to: "/app/inventory/gas",
            label: "Combustíveis",
            Icon: MI.LocalGasStation,
          },
          {
            to: "/app/inventory/products",
            label: "Produtos",
            description: "Estoque mínimo",
            Icon: MI.Store,
          },
        ],
      },
      {
        title: "Outros",
        cards: [
          {
            to: "/app/sys/password",
            label: "Senha técnica",
            Icon: MI.Password,
            isHidden: user?.pessoa?.tipoPapel !== 32,
          },
          {
            to: "https://clienteseguro.multipluscard.com.br/jmvtef",
            label: "Conciliador",
            description: "Cartão TEF",
            Icon: MI.CreditCard,
          },
        ],
      },
    ],
    [user?.pessoa?.tipoPapel]
  );

  return (
    <FLayout.Base>
      <FHeader
        ContentLeft={
          <>
            <IconButton onClick={() => setIsSidebarOpen(true)}>
              <MI.MenuOpen />
            </IconButton>
            <IconButton onClick={toggleDarkTheme}>
              {mode === "dark" ? <MI.Brightness7 /> : <MI.Brightness4 />}
            </IconButton>
          </>
        }
        ContentRight={<FLogo />}
      />

      <FGreatings
        nameUser={user?.pessoa?.nome}
        tipoPapel={user?.pessoa?.tipoPapel}
        nameOrg={currentOrg?.nomeFantasia}
        idOrg={currentOrg?.id}
      />

      <Box sx={{ p: 1, overflowY: "auto", display: "flex", flexWrap: "wrap" }}>
        {dataContent.map((section) => {
          return (
            <Box
              key={section.title}
              sx={{ mb: 1, mt: 1, display: "flex", flexWrap: "wrap", gap: 1 }}
            >
              <Typography variant="subtitle2" sx={{ width: "100%" }}>
                {section.title}
              </Typography>
              {section.cards
                .filter((i) => !i.isHidden)
                .map((i) => (
                  <FButtonBox key={i.label} {...i} />
                ))}
            </Box>
          );
        })}
      </Box>

      <Box sx={{ mt: "auto", display: "flex", justifyContent: "center" }}>
        <Typography variant="overline">A informação perto de você</Typography>
      </Box>

      <FSidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
    </FLayout.Base>
  );
}
